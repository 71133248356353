:root {
  --mainBlue: #2a2a72;
  --lightBlue: #009ffd;
  --mainWhite: #f3f3f3;
  --mainDark: #232528;
  --mainYellow: #ff8400;
  --mainlightblue: #6d84f6;
  --mainDarkBlue: #001822;
  --mainGreen: #06b672;
}
.brandName h5{
  font-family: 'myfont2' ;
  
}
.brandName span{
  font-family: 'myfont1' ;
 
  
}
body {
  visibility: visible !important;
  font-family: "Oswald", sans-serif !important;
  background: var(--mainWhite) !important;
  color: var(--mainDark) !important;
}
.navbarnew-brand-logo{
  width: 5rem;
  height: 4rem;
}
/*-- gif loader--*/
.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8ad;
}
.fp-container .fp-loader {
  top: 30%;
  left: 48%;
  z-index: 1000;
  position: absolute;
}

.text-title {
  font-family: "Pathway Gothic One", sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.text-blue {
  color: var(--mainBlue);
}

.text-bright {
  color: var(--lightBlue);
}

.btn-black {
  background: transparent;
  text-transform: capitalize;
  font-size: 0.8rem !important;
  color: var(--mainDark);
  border-radius: 0rem !important;
  border: 0.1rem solid var(--mainDark) !important;
}

.btn-black:hover {
  background: var(--mainDark) !important;
  color: var(--mainWhite) !important;
}
.table-header {
  border-top: 2px solid grey;
  border-bottom: 2px solid grey;
}
.table-row-details {
  border-bottom: 2px solid grey;
}
.grid-container {
  background-color: rgb(253, 253, 253);
  border-radius: 5px;
}
.cart-icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--mainYellow);
}

.books-count {
  background-color: springgreen;
}

.flip-box {
  background-color: transparent;
  width: 100%;
  height: 200px;
  border: 2px solid #f1f1f1;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}

.searchButton {
  color: #2a2a72;
  border: none;
  height: 40px;
  width: 20%;
}

.searchButton:hover {
  background-color: #fa912e;
}
.searchText {
  font-size: 12px;
}

.th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}
.avatar-icon {
  display: flex;
  justify-content: center;
}

.signupimg {
  text-align: center;
  width: 150px;
  margin-top: -20px;
}
.header-text-sm {
  text-align: center;
  color: var(--mainlightblue);
  line-height: 6rem;
  font-size: 35px;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.header-text {
  text-align: center;
  color: var(--mainlightblue);
  line-height: 6rem;
  font-size: 40px;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.row {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}
/*
.row > div {
  flex: 4;
}
*/
.signup-container {
  width: 500px;
  height: 620px;
  margin-top: 10px;
  border-radius: 12px;
  border: 2px solid var(--mainlightblue);
}
.login-container {
  width: 500px;
  height: 550px;
  margin-top: 50px;
  border-radius: 12px;
  border: 2px solid var(--mainlightblue);
}
.popup-container {
  height: 100%;
  background: url(./images/bg.gif) repeat center center fixed;
  background-color: #000000;
  background-size: cover;
  display: flex;
  border-radius: 0px;
  -moz-box-shadow: inset 0 0 10px var(--mainMustard);
  -webkit-box-shadow: inset 0 0 10px var(--mainMustard);
  box-shadow: inset 0 0 10px var(--mainMustard);
  border: 1px solid var(--mainMustard);
  z-index: 22;
  position: relative;
  justify-content: center;
}

.popup-container::after {
  background: rgba(0, 0, 0, 0.7);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2222;
}

.container-mg {
  background-color: var(--mainDarkBlue);

  display: flex;
  justify-content: center;

  height: 100vh;
}

.button-is-success {
  background-color: #06b672;
  text-transform: capitalize;
  font-size: 18px !important;
  color: var(--mainWhite);
  margin-right: 5px;
  border-radius: 0.8rem !important;
  border: 0.1rem solid #06b672 !important;
}

.button-is-success:hover {
  background-color: #99012f;
  color: azure;
  border: 0rem !important;
  border: 0.1rem solid #99012f !important;
}

.AK-sidebar-text {
  font-size: 22px;
  font-weight: bold;
  margin-top: 25px;
  margin-left: 0rem;
}

.input-phone {
  width: 70% !important;
  border-radius: 8px;
  height: 50px;
  margin: 12px;
}

.input-phonecode {
  width: 20% !important;
  border-radius: 8px;
  height: 50px;
  margin: 12px;
}

.input {
  width: 90%;
  border-radius: 8px;
  height: 50px;
  margin: 12px;
}

.input-phone-s {
  width: 70% !important;
  border-radius: 8px;
  height: 40px;
  margin: 2px;
}

.input-phonecode-s {
  width: 20% !important;
  border-radius: 8px;
  height: 40px;
  margin: 2px;
}

.input-signup {
  width: 90%;
  border-radius: 8px;
  height: 40px;
  margin: 2px;
}

.tst-try {
  background: url(./images/sliderbg1.jpg) no-repeat center center fixed;
  background-size: cover;
}

.work-prog {
  position: relative;
  text-align: center;
  color: rgb(55, 9, 139);
  margin-top: 30px !important;
  background-color: #ffffff !important;
}

.table-accession-title {
  width: 10%;
  background-color: #99e1fd;
}

.table-book-title {
  width: 30%;
  background-color: #99e1fd;
}
.table-author-title {
  width: 20%;
  background-color: #99e1fd;
}

.table-head-cls {
  width: 8%;
  background-color: #99e1fd;
}
.table-head-scope {
  width: 8%;
  background-color: #99e1fd;
}
.table-head-stat {
  width: 12%;
  background-color: #99e1fd;
}

.book-accession-title {
  width: 10%;
}
.book-title {
  width: 30%;
}
.author-title-sps {
  width: 20%;
}
.book-cls-sps {
  width: 8%;
}

.book-scope-sps {
  width: 8%;
}
.book-status-sps {
  width: 12%;
}

.search-input {
  width: 80%;
  height: 40px;
}
.searchoption {
  border: 0.1rem solid var(--mainDark) !important;
  width: 50%;
}
.search-input-ed {
  width: 80%;

  height: 40px;
}
.button-approve {
  background-color: #7108b8;
  border: #ffffff;
  border-radius: 0.3rem;
  color: aliceblue;
}
.button-approve:hover {
  background-color: #048821 !important;
}
.button-del {
  background-color: #7108b8;
  border: #ffffff;
  border-radius: 0.3rem;
  color: aliceblue;
}
.button-del:hover {
  background-color: #e90606 !important;
}

.nav-link-title {
  font-size: 18px;
  color: rgb(255, 255, 255);
}
.navigation__navlinks {
  position: fixed;
  text-align: right;
  right: 20px !important;
  width: 30%;
  background-color: rgb(3, 89, 95);
  color: rgb(253, 253, 253) !important;
  z-index: 9999;
}
/*
.nav-link:hover {
  background-color: rgb(55, 9, 139);
  color: white !important;
}
.navlink-mob:hover {
  background-color: rgb(55, 9, 139);
  color: white !important;
}
*/
.brand_name {
  color: #2a2a72;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .navbar-overlay {
    position: fixed;
    right: 20px;
    z-index: 9988;
  }
  .sidebar-mobile {
    text-align: left;
    top: 80px;
    position: fixed;
    z-index: 9988;
  }
}

@media only screen and (min-width: 580px) {
  .navbar-mobile {
    display: none;
  }
}

/* start templatemo 600 media quires */
@media only screen and (max-width: 860px) {
  .navbar-brand-ak {
    display: none;
  }
  .nav-link-title {
    font-size: 12px !important;
    color: rgb(252, 252, 252);
  }
  .top-navbar {
    position: absolute;
    top: 10px !important;
    right: 10px;
  }
}
@media only screen and (max-width: 680px) {
  .navbar-brand-ak {
    display: none;
  }
  .nav-link-title {
    display: none;
  }
  .nav-link-title-small {
    font-size: 12px !important;
    color: rgb(252, 252, 252);
    position: absolute !important;
    left: 3px !important;
    line-height: 0.8 !important;
  }
  .top-navbar {
    position: absolute;
    top: 10px !important;
    right: 10px;
  }

  .sm-container {
    display: none;
  }
}

@media only screen and (max-width: 580px) {
  
  .navbar-bg {
    display: none;
  }
  .signupimg {
    width: 110px;
    margin-top: -20px;
  }

  .nav-link-title-small {
    font-size: 12px !important;
    color: rgb(252, 252, 252);
    position: absolute !important;
    left: 15px !important;
    top: 15px !important;
  }
  .top-navbar {
    position: absolute;
    top: 10px !important;
    right: 10px;
  }
  li {
    font-size: 10px !important;
  }

  .search-input {
    width: 70%;
    height: 30px;
  }
  .search-input-ed {
    width: 70%;

    height: 30px;
  }

  .searchButton {
    height: 30px;
    width: 30%;
  }

  .book-title-sps {
    width: 50% !important;
    font-size: 10px;
  }
  .author-title-sps {
    width: 15% !important;
    font-size: 10px;
  }
  .book-cls-sps {
    width: 5% !important;
    font-size: 10px;
  }
  .book-status-sps {
    width: 15% !important;
    font-size: 10px;
  }
  .book-scope-sps {
    width: 15%;
    font-size: 10px;
  }

  .table-head-btitle {
    width: 50% !important;
    font-size: 10px;
  }

  .table-head-atitle {
    width: 15% !important;
    font-size: 10px;
  }

  .table-head-cls {
    width: 5% !important;
    font-size: 10px;
  }

  .table-head-stat {
    width: 15% !important;
    font-size: 10px;
  }

  .table-head-scope {
    width: 15% !important;
    font-size: 10px;
  }

  .bg-container {
    display: none;
  }
  .sm-container {
    display: inherit !important;
    font-size: 10px;
    width: 100% !important;
  }
  .brandName h5{
    font-size: 14px !important;
    font-family: 'myfont2' ;
    
  }
  .brandName span{
    font-family: 'myfont1' ;
    font-size: 20px;
    
  }
  .login-container {

    border: none;
  }
  .signup-container{
  
    border: none;
  }
}
