/*------------------------------------------------------------------
    Version: 1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
    [Table of contents]

    1. IMPORT FONTS
    2. IMPORT FILES
    3. SKELETON
    4. WP CORE
    5. HEADER
    6. SECTIONS
    7. SECTIONS
    8. PORTFOLIO
    9. TESTIMONIALS
    10. PRICING TABLES
    11. ICON BOXES
    12. MESSAGE BOXES
    13. FEATURES
    14. CONTACT
    15. FOOTER
    16. MISC
    17. BUTTONS
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
    IMPORT FONTS
-------------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

/*------------------------------------------------------------------
    IMPORT FILES


@import url(flaticon.css);
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
    HEADER
-------------------------------------------------------------------*/

.top-navbar {
  position: absolute;
  top: 30px;
  right: 10px;
  
}

.top-navbar .navbar-expand-lg .navbar-nav {
  background: #001822;
}

.top-navbar .navbar-light .navbar-nav .nav-item {
  position: relative;
}
.top-navbar .navbar-light .navbar-nav .nav-link {
  padding: 5px 9px;
  color: #001822;
  position: relative;
}

.top-navbar .navbar-light .navbar-nav .nav-item::before {
  position: absolute;

  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 0%;
  display: block;
  opacity: 0;
  border-radius: 5px;
  background-color: #068640;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}

.top-navbar .navbar-light .navbar-nav .nav-item.active::before {
  opacity: 1;
  height: 100%;
}

.top-navbar .navbar-light .navbar-nav .nav-item:hover::before {
  opacity: 1;
  height: 100%;
}

/*----------------------------------------*/

.icon_wrap {
  background-color: #001822;
  width: 100px;
  height: 100px;
  display: block;
  line-height: 100px;
  font-size: 65px;
  color: #fcfcfc;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  padding: 0 !important;
  border: 0 !important;
}

.stat-wrap h3 {
  font-size: 28px;
  font-weight: 500;
  color: rgb(2, 68, 22);
  margin: 0;
  padding: 0;
  line-height: 2;
}

.stat-wrap p {
  font-size: 38px;
  color: #001822;
  margin: 0;
  font-weight: 300;
  padding: 4px 0 0;
  line-height: 2 !important;
}

/*-----------Digit Roll----------------*/

.home-con {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8; /* for demo purpose  */
}
.overlay-top-con {
  z-index: 9;
  margin: 20px; /* for demo purpose  */
}
.con-test{
  border-right: 2px Solid black;
  background-color: #001822;
  height: 1000px;

 
  box-shadow: 10px 0 5px -2px #888;
}
#tst-try {
  background: url(../images/sliderbg1.jpg) no-repeat center center fixed;
  background-size: cover;
}
.dash-head{
  background-color: #2a2a72;
}
.avatar-icon{
 
  margin: 2px;
  
}
.dash-board{
  
  background-color: #d1d6fc;
}
.list-group-item{
  width: 100%;
}